import { render, staticRenderFns } from "./GeneralPricing.vue?vue&type=template&id=686078ec&"
import script from "./GeneralPricing.vue?vue&type=script&lang=js&"
export * from "./GeneralPricing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCardText,VCheckbox,VDatePicker,VRadio,VRadioGroup,VTextField})
